<style lang="scss"></style>

<template>
  <svg
    width="215"
    height="35"
    viewBox="0 0 215 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="215"
      height="35"
    >
      <rect
        x="0.476959"
        y="0.47168"
        width="214.175"
        height="33.5574"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M5.47133 33.9663H24.449C33.5292 33.9663 38.5234 27.701 38.6142 19.6195V6.90723C38.6142 -1.17417 33.5292 -7.43953 24.449 -7.43953H17.548V-23.3299H36.2533V-33.5906H5.47133V33.9663ZM17.548 24.1597V2.36712H21.0893C25.0846 2.36712 26.4467 4.54637 26.4467 9.08649V17.7127C26.4467 22.162 24.9938 24.1597 21.0893 24.1597H17.548ZM72.5707 9.8129V33.9663H84.1934V-33.5906H72.2983L63.9444 -5.80509C61.5836 1.91311 60.494 6.54402 59.3135 11.1749C59.5859 6.72563 59.7675 2.18551 59.7675 -9.43718V-33.5906H48.1448V33.9663H60.0399L68.3938 6.18081C70.7546 -1.62818 72.1166 -7.16712 73.2063 -11.344C72.8431 -7.07632 72.5707 -1.44658 72.5707 9.8129ZM126.765 -22.8759V-33.5906H92.8047V-22.8759H103.792V33.9663H115.868V-22.8759H126.765ZM135.354 33.9663H155.875C165.954 33.9663 170.858 28.3366 170.858 19.1655V9.26809C170.858 1.09589 167.044 -1.71898 163.594 -2.99022C166.681 -4.17065 169.859 -6.89471 169.859 -14.4313V-20.4243C169.859 -29.4137 166.318 -33.5906 155.875 -33.5906H135.354V33.9663ZM152.606 -24.5104C156.693 -24.5104 158.145 -22.8759 158.145 -18.4266V-13.0693C158.145 -8.61996 156.693 -6.62231 152.697 -6.62231H147.249V-24.5104H152.606ZM152.697 3.09354C156.965 3.09354 158.599 5.00038 158.599 9.7221V17.8943C158.599 22.7068 157.056 24.4321 152.879 24.4321H147.249V3.09354H152.697ZM180.599 -33.5906V33.9663H210.836V23.1608H192.403V4.45557H207.295V-6.3499H192.403V-23.0575H210.291V-33.5906H180.599Z"
        fill="#00C1F5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBattleBottom',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
