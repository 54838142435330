<style lang="scss">
.vm--container {
  z-index: 9999;
}
.modal-prizes {
  @apply overflow-auto;
  &.vm--modal {
    background-color: transparent;
    z-index: 99999;
  }
  &__content {
    @apply text-left;
    padding: 16px;
    background: #1c1b1b;
    border-radius: 8px;
  }
  &__title {
    @apply font-druk_text_cy flex justify-between;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__close {
    @apply font-druk_text_cy;
    padding: 11px 24px 11px 24px;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
    &:hover:active {
      color: rgba(255, 255, 255, 0.7);
    }
    &:focus {
      outline: none;
    }
  }
}
@screen lg {
  .modal-prizes {
    &__content {
      padding: 40px 42px 48px 42px;
    }
    &__title {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
</style>

<template>
  <modal
    :name="name"
    adaptive
    clickToClose
    classes="modal-prizes"
    :maxWidth="900"
    width="900px"
    height="auto"
    scrollable
  >
    <div class="modal-prizes__content">
      <div class="modal-prizes__title mb-6">
        <div>Все призы</div>
        <button class="modal-prizes__close" @click="$modal.hide(name)">
          Закрыть
        </button>
      </div>
      <div class="-m-2">
        <RewardItem
          v-for="(item, idx) in rewards"
          :key="`rewards_${idx}`"
          :title="item.title"
          :place="item.place"
          :image="item.image"
          class="w-260px inline-block m-2"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { REWARDS } from '@/consts.js'
import RewardItem from '@/components/RewardItem.vue'

export default {
  name: 'ModalPrizes',
  components: { RewardItem },
  data() {
    return {
      name: 'modal-prizes',
      rewards: REWARDS,
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
