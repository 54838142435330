<style lang="scss"></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 2.00033H8.46442L8.6894 2.83961L13.3359 20.1729L13.4683 20.667H13.9798H14.6667H15.5356L15.3106 19.8277L10.6641 2.49438L10.5317 2.00033H10.0202H9.33333Z"
      fill="#00C1F5"
      stroke="#00C1F5"
      stroke-width="1.33333"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSlash',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
