<style lang="scss">
.stream {
}
</style>

<template>
  <div class="stream">
    <iframe
      :src="url"
      frameborder="0"
      allowfullscreen
      width="100%"
      height="500px"
      style="border: solid 1px #313131; borderRadius: 8px"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Stream',
  data() {
    return {}
  },
  props: {
    url: {
      type: String,
    },
  },
}
</script>