<style lang="scss">
.ticker {
  @apply mt-12 lg:mt-19;

  &__container {
    @apply relative border-t border-b py-3 lg:py-4;
    border-color: rgba(105, 108, 136, 0.7);
    mask-image: radial-gradient(
      circle,
      rgba(20, 20, 20, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__text {
    @apply inline-block relative font-druk_text_cy text-32 lg:text-42 font-medium uppercase px-5 whitespace-nowrap;
    color: #696c88;

    &::after {
      @apply absolute border rounded-full;
      content: '';
      top: 50%;
      right: -4px;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      border-color: rgba(white, 0.4);
    }
  }
}
</style>

<template>
  <div class="ticker">
    <div class="ticker__container">
      <marquee-text :repeat="10" :duration="8">
        <div class="ticker__text">{{text}}</div>
      </marquee-text>
    </div>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'Ticker',
  props: {
    text: { type: String },
  },
  components: {
    MarqueeText,
  },
}
</script>
