<style lang="scss" scoped>
.example-3d {
  width: 100%;
  height: 331px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    width: 287px;
    height: 331px;
    & .issue-card {
      @apply text-left;
      margin: 0;
      width: 100%;
    }
    &.swiper-slide-active {
    }
  }

  .swiper-pagination {
    display: none;
    & .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #ffffff;
    }
  }
}
.swiper-container {
  overflow: visible;
}
</style>

<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="item in issueslanding" :key="item.id">
        <ButtonAuth type="blank"><issue-card v-bind="item" /></ButtonAuth
      ></swiper-slide>
      <div slot="pagination" class="swiper-pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import IssueCard from '@/components/IssueCard.vue'
import ButtonAuth from '@/components/ButtonAuth.vue'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import useGame from '@/composables/useGame'

export default {
  name: 'SwiperIssues',
  components: {
    Swiper,
    SwiperSlide,
    IssueCard,
    ButtonAuth,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 3,
        keyboard: {
          enabled: true,
        },
        mousewheel: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  props: {},
  setup() {
    const { issueslanding } = useGame()
    return { issueslanding }
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
