<style lang="scss" scoped>
.speakers {
  color: #fff;
  text-align: left;
  &__block {
    width: 180px;
  }
  &__title {
    @apply font-druk_text_cy;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  &__subtitle {
    @apply font-graphik_lv_regular;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 25px;
    & span {
      color: #00b4e3;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;
    & li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 80px;
    }
  }
  &__avatar {
    width: 96px;
    height: 120px;
    background: radial-gradient(
      132.67% 121.04% at 50% 50%,
      rgba(0, 6, 63, 0) 26.79%,
      rgba(0, 16, 165, 0.5) 67.71%
    );
    background-size: 100px;
    mix-blend-mode: normal;
    border-radius: 8px;
    margin-right: 18px;
  }
  &__name {
    @apply font-druk_text_cy;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
  &__descr {
    @apply font-graphik_lv_regular;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.6;
    margin-bottom: 24px;
  }
  &__quote {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    word-wrap: break-word;
  }
}
@screen lg {
  .speakers {
    &__block {
      width: 280px;
    }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 32px;
    }
    &__avatar {
      width: 200px;
      height: 240px;
      background-size: cover;

      margin-right: 24px;
    }
    &__subtitle {
      font-size: 50px;
      line-height: 64px;
    }
    &__quote {
      font-size: 20px;
      line-height: 32px;
    }
    &__name {
      font-size: 32px;
      line-height: 40px;
    }
    &__descr {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>

<template>
  <div class="speakers">
    <h2 class="speakers__title">спикеры</h2>
    <p class="speakers__subtitle">
      Эксперты из
      <span>Открытия</span> выступят с
      <br />докладами и поделятся своим опытом
    </p>
    <ul class="speakers__list">
      <li v-for="(speaker, ind) in speakers" :key="speaker.name">
        <div class="speakers__avatar" :style="{ backgroundImage: `url('${speaker.image}')` }"></div>

        <div class="speakers__block">
          <p class="speakers__name">{{ speaker.name }}</p>
          <p class="speakers__descr">{{ speaker.descr }}</p>
          <p class="speakers__quote">{{ speaker.quote }}</p>
        </div>
      </li>
    </ul>
    <div class="about__list">
      <div v-for="{ title, descr } in items" :key="title" class="about__list-item">
        <div class="about__list-title">{{ title }}</div>
        <div class="about__list-text">{{ descr }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Speakers',
  data() {
    return {
      speakers: [
        {
          name: 'Максим Дементьев',
          descr: 'Начальник отдела моделирования, департамент CRM МСБ',
          quote:
            '«Моделирование и работа с данными на рынке малого и среднего бизнеса»',
          image: '/images/speaker_1.jpg',
        },
        {
          name: 'Павел николаев',
          descr: 'Управляющий директор, департамент интегрированных рисков',
          quote:
            '«Опыт внедрения общебанковской платформы моделирования на принципах MLops»',
          image: '/images/speaker_2.jpg',
        },
        {
          name: 'Алексей Чебыкин',
          descr: 'Директор центра валидации',
          quote: '«Валидация. Что? Как? Зачем?»',
          image: '/images/speaker_3.jpg',
        },
        {
          name: 'Евгений Степанов',
          descr: 'Директор центра моделирования',
          quote:
            '«Разработка и деплой скоринговых моделей в розничном кредитовании» и еще 2 темы',
          image: '/images/speaker_4.jpg',
        },
        {
          name: 'Сергей Загребнев',
          descr: 'Директор центра DS&A, департамент интегрированных рисков',
          quote: '«Центр Data Science & Analytics – о команде и задачах»',
          image: '/images/speaker_5.jpg',
        },
        {
          name: 'Елена Ненахова',
          descr:
            'Начальник отдела ИТ-подбора, департамент подбора и развития персонала',
          quote: '«Bank’s not dead: возможности для Data Science в банке»',
          image: '/images/speaker_6.jpg',
        },
      ],
      items: [
        {
          title: 'Лучшие спикеры',
          descr:
            'Мы позвали лучших специалистов из разных областей, специально для тебя',
        },
        {
          title: 'Интересные темы',
          descr:
            'Самые интересные темы и горячие темы индустрии в рамках одного ивента – и все это онлайн',
        },
        {
          title: 'Живой фидбек',
          descr:
            'Общайся со специалистами, задавай каверзные вопросы и не бойся быть настойчивым',
        },
      ],
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
