<style lang="scss"></style>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2503 10.5417C19.2535 11.7516 18.9708 12.9451 18.4253 14.025C17.7785 15.3191 16.7842 16.4076 15.5538 17.1685C14.3234 17.9295 12.9054 18.3328 11.4586 18.3334C10.2488 18.3365 9.05524 18.0538 7.97531 17.5084L2.75031 19.25L4.49197 14.025C3.94649 12.9451 3.66382 11.7516 3.66697 10.5417C3.66753 9.09497 4.07087 7.67697 4.8318 6.44653C5.59273 5.21609 6.68121 4.2218 7.97531 3.57503C9.05524 3.02955 10.2488 2.74687 11.4586 2.75003H11.917C13.8276 2.85544 15.6323 3.66189 16.9854 5.01498C18.3384 6.36807 19.1449 8.17271 19.2503 10.0834V10.5417Z"
      stroke="#00C1F5"
      stroke-width="1.44794"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMessage',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
