<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97784 3.60601C9.36969 2.4 9.56562 1.79699 9.92889 1.79699C10.2922 1.79699 10.4881 2.39999 10.88 3.60601L11.725 6.20682C11.8333 6.54015 11.8875 6.70682 12.0189 6.80231C12.1503 6.8978 12.3256 6.8978 12.6761 6.8978H15.4107C16.6788 6.8978 17.3128 6.8978 17.4251 7.24329C17.5373 7.58879 17.0244 7.96146 15.9985 8.70682L13.7861 10.3142C13.5026 10.5202 13.3608 10.6232 13.3106 10.7777C13.2604 10.9322 13.3145 11.0989 13.4228 11.4322L14.2679 14.0331C14.6598 15.2391 14.8557 15.8421 14.5618 16.0556C14.2679 16.2691 13.755 15.8964 12.7291 15.1511L10.5167 13.5437C10.2331 13.3377 10.0914 13.2347 9.92889 13.2347C9.76643 13.2347 9.62466 13.3377 9.34111 13.5437L7.12873 15.1511C6.10283 15.8964 5.58988 16.2691 5.29599 16.0556C5.0021 15.8421 5.19803 15.2391 5.58988 14.0331L6.43494 11.4322C6.54324 11.0989 6.5974 10.9322 6.5472 10.7777C6.49699 10.6232 6.35522 10.5202 6.07167 10.3142L3.85929 8.70682C2.83339 7.96146 2.32044 7.58879 2.4327 7.24329C2.54496 6.8978 3.17899 6.8978 4.44707 6.8978H7.18173C7.53221 6.8978 7.70746 6.8978 7.83889 6.80231C7.97032 6.70682 8.02448 6.54015 8.13278 6.20682L8.97784 3.60601Z"
      fill="#00C0F2"
      fill-opacity="0.44"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconRegistered',
}
</script>
