<style lang="scss"></style>

<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9498 9.05023C13.1873 8.28784 12.2799 7.72343 11.2897 7.38177C12.3502 6.65139 13.0469 5.42905 13.0469 4.04688C13.0469 1.81543 11.2314 0 9 0C6.76856 0 4.95312 1.81543 4.95312 4.04688C4.95312 5.42905 5.64982 6.65139 6.71029 7.38177C5.72017 7.72343 4.81269 8.28784 4.05026 9.05023C2.72814 10.3724 2 12.1302 2 14H3.09375C3.09375 10.7433 5.74328 8.09375 9 8.09375C12.2567 8.09375 14.9062 10.7433 14.9062 14H16C16 12.1302 15.2719 10.3724 13.9498 9.05023ZM9 7C7.37165 7 6.04688 5.67525 6.04688 4.04688C6.04688 2.4185 7.37165 1.09375 9 1.09375C10.6283 1.09375 11.9531 2.4185 11.9531 4.04688C11.9531 5.67525 10.6283 7 9 7Z"
      fill="#00C1F5"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPlayer',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
