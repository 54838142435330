var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stats"},[_c('div',{staticClass:"landing-title mb-4"},[_vm._v("Статистика турнира")]),_c('div',{staticClass:"stats__list"},_vm._l((_vm.stats),function(ref){
var id = ref.id;
var title = ref.title;
return _c('div',{key:id,staticClass:"stats__list-item"},[_c('div',{staticClass:"stats__list-item__value"},[_vm._v(" "+_vm._s(_vm.mainStats[id])+" ")]),_c('div',{staticClass:"stats__list-item__title"},[_vm._v(_vm._s(title))])])}),0),_c('div',{staticClass:"stats__events"},_vm._l((_vm.events),function(ref,idx){
var duration = ref.duration;
var username = ref.username;
var icon = ref.icon;
var text = ref.text;
var task = ref.task;
return _c('div',{key:idx,staticClass:"stats__events-item"},[_c('span',{staticClass:"stats__events-time"},[_vm._v(" "+_vm._s(duration)+" ")]),_c(icon,{tag:"component",staticClass:"stats__events-icon"}),_c('span',{staticClass:"stats__events-name"},[_vm._v(" "+_vm._s(username)+" ")]),_c('span',{staticClass:"stats__events-text"},[_vm._v(" "+_vm._s(text))]),_c('span',{staticClass:"stats__events-task"},[_vm._v(" "+_vm._s(task)+" ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }