<style lang="scss">
.stats {
  @apply text-white;

  &__list {
    @apply flex items-center mb-6;

    &-item {
      &:not(:last-child) {
        @apply relative pr-6 mr-6;

        &::after {
          @apply absolute bg-light-gray;
          content: '';
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 26px;
          width: 1px;
        }
      }

      &__value {
        @apply font-druk_text_cy text-left mb-2;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
      }

      &__title {
        @apply text-12 lg:text-14 text-left;
      }
    }
  }

  &__events {
    @apply text-14 lg:text-20 text-light-gray overflow-hidden text-left;
    height: 420px;

    &-item {
      @apply flex items-center flex-wrap;

      &:not(:last-child) {
        @apply mb-2;
      }

      &:nth-child(9) {
        opacity: 0.9;
      }

      &:nth-child(10) {
        opacity: 0.6;
      }

      &:nth-child(11) {
        opacity: 0.3;
      }

      &:nth-child(12) {
        opacity: 0.1;
      }
    }

    &-name {
      @apply pr-2 text-white;
    }
    &-text {
      @apply pr-2;
    }
    &-task {
      @apply text-white;
    }

    &-icon {
      @apply mx-4;
    }
  }
}
@screen lg {
  .stats {
    &__list {
      &-item {
        &__value {
          font-size: 24px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="stats">
    <div class="landing-title mb-4">Статистика турнира</div>
    <div class="stats__list">
      <div v-for="{ id, title } in stats" :key="id" class="stats__list-item">
        <div class="stats__list-item__value">
          {{ mainStats[id] }}
        </div>
        <div class="stats__list-item__title">{{ title }}</div>
      </div>
    </div>
    <div class="stats__events">
      <div
        v-for="({ duration, username, icon, text, task }, idx) in events"
        :key="idx"
        class="stats__events-item"
      >
        <span class="stats__events-time">
          {{ duration }}
        </span>
        <component :is="icon" class="stats__events-icon" />
        <span class="stats__events-name">
          {{ username }}
        </span>
        <span class="stats__events-text"> {{ text }}</span>
        <span class="stats__events-task"> {{ task }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import useStats from '../composables/useStats'
import IconFinalist from '@/components/Icons/events/IconFinalist.vue'
import IconSolved from '@/components/Icons/events/IconSolved.vue'
import IconRegistered from '@/components/Icons/events/IconRegistered.vue'
import { computed, onMounted } from '@vue/composition-api'
import { formatDistanceToNow } from 'date-fns'
import { ru } from 'date-fns/locale'

export default {
  name: 'MainStatsWidget',
  components: {
    IconFinalist,
    IconSolved,
    IconRegistered,
  },
  data() {
    return {
      stats: [
        { id: 'players', title: 'Игроков' },
        { id: 'tries', title: 'Всего попыток' },
        { id: 'finalists', title: 'Прошли в финал' },
      ],
    }
  },
  setup() {
    const { updateStats, mainEvents, mainStats, isLoading } = useStats()
    updateStats()
    const eventsData = {
      registered: { text: 'присоединился к турниру', icon: 'IconRegistered' },
      finalist: { text: 'прошел в финал', icon: 'IconFinalist' },
      solved: { text: 'выполнил задание', icon: 'IconSolved' },
      unsolved: { text: 'отправил неверное решение', icon: '' },
      dataset_downloaded: { text: 'начал задание', icon: '' },
    }
    const tasksData = {
      predict: 'Predict',
      news: 'News',
      gender: 'Gender',
      covid19_1: 'Covid-19',
      paris: 'Paris',
      airport: 'Airport',
    }
    const events = computed(() => {
      return mainEvents.value.map((item) => {
        return {
          duration: formatDistanceToNow(new Date(item.time), {
            locale: ru,
          }),
          username: item.username,
          icon: eventsData[item.type].icon,
          text: eventsData[item.type].text,
          task: tasksData[item.task_id],
        }
      })
    })

    return {
      events,
      mainStats,
      isLoading,
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
