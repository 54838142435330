<style lang="scss">
.waiting-for-you {
  @apply mt-11 lg:mt-21;

  &__title {
    @apply font-druk_text_cy lg:mb-8;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  &__list {
    @apply flex flex-wrap justify-center lg:-mx-2 -my-2;
  }

  &__item {
    @apply w-full lg:mx-2 my-2 rounded lg:rounded-lg relative overflow-hidden;
    padding: 18px 20px 28px 54px;
    background: linear-gradient(
      180deg,
      #060606 0%,
      rgba(22, 22, 22, 0.76) 100%
    );
    border: 1px solid #313131;
  }

  &__card {
    @apply flex items-start;

    &-icon {
      @apply absolute rounded-full flex items-center justify-center;
      top: 17px;
      left: 17px;
      --size: 26px;
      width: var(--size);
      height: var(--size);
      background: rgba(255, 255, 255, 0.1);
      border: 0.36px solid #242424;
      & svg {
        width: 13px;
      }
    }

    &-info {
      @apply text-left text-white;
    }

    &-title {
      @apply font-druk_text_cy text-20 lg:text-24 mb-2 lg:mb-3;
    }

    &-text {
      @apply font-graphik_lv_regular leading-6 text-14 lg:text-16 leading-5 lg:leading-6;
    }
  }
}
.shedule-block {
  margin-right: -15px;
  margin-left: -15px;
}
@screen lg {
  .waiting-for-you {
    &__title {
      font-size: 80px;
      line-height: 133px;
    }
    &__item {
      max-width: 387px;
      padding: 24px 26px 24px 73px;
      min-height: 210px;
    }
    &__card {
      &-icon {
        top: 24px;
        left: 24px;
        --size: 35px;
        & svg {
          width: 18px;
        }
      }
    }
  }
  .shedule-block {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 100px;
  }
}

.flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 545px;
  transform: skewX(-45deg); // Наклон
  animation: flareAnimation;
  left: 0;
  background: linear-gradient(
    106.41deg,
    rgba(6, 6, 6, 0) 0%,
    rgba(16, 23, 31, 0) 41.15%,
    rgba(47, 56, 66, 0.6) 46.35%,
    rgba(26, 34, 43, 0) 54.69%,
    rgba(22, 22, 22, 0) 100%
  );
  animation: flareAnimation 2s infinite linear; // Время и тип анимации можно менять
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
</style>

<template>
  <section class="waiting-for-you">
    <h2 class="waiting-for-you__title">Расписание финала</h2>
    <div class="landing-block shedule-block mb-4 md:mx-2">
      <shedule-table :list="list" />
      <tg-chat variant="left" />
    </div>
    <!-- <div class="waiting-for-you__list">
      <div
        v-for="({ icon, title, text }, idx) in items"
        :key="`wfy__${idx}`"
        class="waiting-for-you__item"
      >
        <div v-if="flareItem === idx" class="flare z-10"></div>
        <div class="waiting-for-you__card">
          <div class="waiting-for-you__card-icon z-20">
            <component :is="icon" />
          </div>
          <div class="waiting-for-you__card-info z-20">
            <div class="waiting-for-you__card-title">{{ title }}</div>
            <p class="waiting-for-you__card-text" v-html="text" />
          </div>
        </div>
      </div>
    </div>-->
  </section>
</template>

<script>
import IconSlash from './Icons/IconSlash.vue'
import IconPrize from './Icons/IconPrize.vue'
import IconBag from './Icons/IconBag.vue'
import IconMessage from './Icons/IconMessage.vue'
import IconSmile from './Icons/IconSmile.vue'
import IconPlayer from './Icons/IconPlayer.vue'
import TgChat from './TgChat.vue'
import SheduleTable from './SheduleTable.vue'

export default {
  components: {
    IconSlash,
    IconPrize,
    IconBag,
    IconMessage,
    IconSmile,
    IconPlayer,
    TgChat,
    SheduleTable,
  },
  name,
  TgChat: 'WaitingForYou',
  data() {
    return {
      flareItem: null,
      interval: null,
      items: [
        {
          title: 'Интересные задачи',
          text: 'Предстоит определить загрузку отеля, посчитать скорость распространения ковида, обучить нейронную сеть и&nbsp;не&nbsp;только: из&nbsp;шести задач выбери две самых интересных, реши их&nbsp;и&nbsp;пройди в&nbsp;финал!',
          icon: 'icon-slash',
        },
        {
          title: 'Реальный кейс в финале',
          text: 'В&nbsp;финале тебя ждёт реальная задача из&nbsp;практики &laquo;Открытия&raquo;.',
          icon: 'icon-bag',
        },
        {
          title: 'Индивидуальный турнир',
          text: 'У&nbsp;нас каждый сам за&nbsp;себя: участие команд не&nbsp;предусмотрено. Одно место&nbsp;&mdash; один участник.',
          icon: 'icon-player',
        },
        // {
        //   title: 'Кайфовые призы',
        //   text: '1 место — Sony PlayStation 5,<br />2 место — электросамокат Ninebot by Segway,<br />3 место — планшет iPad.<br />И ещё десятки призов.',
        //   icon: 'icon-prize',
        // },
        {
          title: 'Возможность роста',
          text: 'Обменяться опытом с&nbsp;коллегами по&nbsp;Data Science, пообщаться с&nbsp;экспертами и&nbsp;познакомиться с&nbsp;организаторами. Лучшим участникам могут предложить стать частью команды Data Science в&nbsp;&laquo;Открытии&raquo;.',
          icon: 'icon-bag',
        },
        {
          title: 'Крутой движ',
          text: 'В&nbsp;финале&nbsp;&mdash; конкурсы, призы, выступления крутых спикеров о&nbsp;Data Science и&nbsp;не&nbsp;только.',
          icon: 'icon-smile',
        },
      ],
      list: [
        { time: '14:00', title: 'Начало трансляции' },
        {
          time: '14:05',
          title:
            'Выступление <span>Елены Ненаховой</span>: Bank’s not dead: возможности для DS в банке',
        },
        {
          time: '14:40',
          title:
            'Выступление <span>Максима Дементьева</span>: Моделирование и работа с данными на рынке малого и среднего бизнеса',
        },
        { time: '15:00', title: 'Обзор задач отборочного тура' },
        {
          time: '15:10',
          title:
            'Выступление <span>Павла Николаева</span>: Опыт внедрения общебанковской платформы моделирования на принципах MLops',
        },
        {
          time: '15:30',
          title:
            'Выступление <span>Алексея Чебыкина</span>: Валидация. Что? Как? Зачем?',
        },
        {
          time: '15:45',
          title: 'Обзор дополнительной задачи финала',
        },
        {
          time: '15:55',
          title:
            'Награждение лучших участников, решивших дополнительную задачу',
        },
        {
          time: '16:10',
          title:
            'Выступление <span>Сергея Загребнева</span>: Центр Data Science & Analytics – о команде и задачах',
        },
        {
          time: '16:30',
          title:
            'Выступление <span>Евгения Степанова</span>: Разработка и деплой скоринговых моделей в розничном кредитовании. Автоматическое переобучение моделей. Основные задачи и инструменты разработки в розничных рисках',
        },
        {
          time: '16:50',
          title: 'Обзор решения обязательной задачи финала',
        },
        {
          time: '17:00',
          title: 'Награждение победителей турнира',
        },
        {
          time: '17:10',
          title: 'Афтерпати в SpatialChat',
        },
      ],
    }
  },
  methods: {
    animateFlare() {
      this.interval = setInterval(() => {
        this.flareItem = this.$randomNumber(0, this.items.length - 1)
      }, 2000)
    },
  },
  mounted() {
    this.animateFlare()
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>
