<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2424 2.05901C5.64717 2.05901 1.92199 5.7842 1.92199 10.3794C1.92199 14.9747 5.64717 18.6999 10.2424 18.6999C14.8377 18.6999 18.5629 14.9747 18.5629 10.3794C18.5629 5.7842 14.8377 2.05901 10.2424 2.05901ZM0.257751 10.3794C0.257751 4.86506 4.72804 0.394775 10.2424 0.394775C15.7568 0.394775 20.2271 4.86506 20.2271 10.3794C20.2271 15.8938 15.7568 20.3641 10.2424 20.3641C4.72804 20.3641 0.257751 15.8938 0.257751 10.3794ZM9.65367 6.12649C9.97864 5.80152 10.5055 5.80152 10.8305 6.12649L14.4915 9.78751C14.6299 9.92591 14.7093 10.1009 14.7299 10.2814C14.7337 10.3138 14.7357 10.3468 14.7357 10.3803C14.7357 10.6374 14.6191 10.8673 14.4358 11.0199L10.8305 14.6253C10.5055 14.9503 9.97864 14.9503 9.65367 14.6253C9.32871 14.3004 9.32871 13.7735 9.65367 13.4485L11.8898 11.2124H6.58151C6.12195 11.2124 5.7494 10.8399 5.7494 10.3803C5.7494 9.92075 6.12195 9.5482 6.58151 9.5482H11.8986L9.65367 7.30328C9.32871 6.97831 9.32871 6.45145 9.65367 6.12649Z"
      fill="#949CB7"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSolved',
}
</script>
