<style lang="scss"></style>

<template>
  <svg
    width="215"
    height="39"
    viewBox="0 0 215 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="battle-top"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="215"
      height="39"
    >
      <rect
        x="0.411865"
        y="0.966309"
        width="214.175"
        height="37.5053"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#battle-top)">
      <path
        d="M5.40599 71.9663H24.3837C33.4639 71.9663 38.458 65.701 38.5488 57.6195V44.9072C38.5488 36.8258 33.4639 30.5605 24.3837 30.5605H17.4827V14.6701H36.188V4.40942H5.40599V71.9663ZM17.4827 62.1597V40.3671H21.024C25.0193 40.3671 26.3813 42.5464 26.3813 47.0865V55.7127C26.3813 60.162 24.9285 62.1597 21.024 62.1597H17.4827ZM72.5053 47.8129V71.9663H84.128V4.40942H72.2329L63.8791 32.1949C61.5182 39.9131 60.4286 44.544 59.2482 49.1749C59.5206 44.7256 59.7022 40.1855 59.7022 28.5628V4.40942H48.0795V71.9663H59.9746L68.3284 44.1808C70.6893 36.3718 72.0513 30.8329 73.1409 26.656C72.7777 30.9237 72.5053 36.5534 72.5053 47.8129ZM126.699 15.1241V4.40942H92.7393V15.1241H103.726V71.9663H115.803V15.1241H126.699ZM135.289 71.9663H155.81C165.889 71.9663 170.792 66.3366 170.792 57.1655V47.2681C170.792 39.0959 166.979 36.281 163.528 35.0098C166.615 33.8294 169.794 31.1053 169.794 23.5687V17.5757C169.794 8.58632 166.252 4.40942 155.81 4.40942H135.289V71.9663ZM152.541 13.4896C156.627 13.4896 158.08 15.1241 158.08 19.5734V24.9307C158.08 29.38 156.627 31.3777 152.632 31.3777H147.184V13.4896H152.541ZM152.632 41.0935C156.9 41.0935 158.534 43.0004 158.534 47.7221V55.8943C158.534 60.7068 156.99 62.4321 152.814 62.4321H147.184V41.0935H152.632ZM180.534 4.40942V71.9663H210.771V61.1608H192.338V42.4556H207.23V31.6501H192.338V14.9425H210.226V4.40942H180.534Z"
        fill="#00C1F5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBattleTop',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
