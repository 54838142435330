<style lang="scss">
.landing {
  @apply font-graphik_lv_regular;
}

.landing-title {
  @apply font-druk_text_cy font-medium text-left text-white uppercase;
  font-size: 24px;
  line-height: 32px;
}

@screen lg {
  .landing-title {
    font-size: 32px;
    line-height: 40px;
  }
}

.landing-head {
  @apply flex mt-7 lg:mt-20 flex-col lg:flex-row justify-between;
  &__left {
    @apply relative text-left mb-12 lg:mb-0;

    &-image {
      @apply absolute;
      top: -13px;
      left: -20px;
      width: 286px;
    }
  }
  &__right {
    @apply flex flex-col;
  }
  &__second {
    @apply flex justify-between flex-col lg:flex-row mt-4 lg:mt-3;
  }
  &__title {
    @apply font-druk_text_cy font-medium text-white text-left lg:mb-14 uppercase;
    max-width: 561px;
    font-size: 44px;
    line-height: 39px;

    &-image {
      @apply inline-block;
      vertical-align: baseline;
    }
  }

  &__deadlines {
    @apply flex lg:items-end flex-col w-max lg:w-auto mx-auto;

    &-title {
      @apply font-druk_text_cy text-white uppercase mb-4 lg:text-right;
      font-size: 12px;
      line-height: 16px;
    }

    &-date {
      @apply mt-4 lg:mt-8;
    }

    &-text {
      @apply font-graphik_lv_regular text-white lg:text-right mb-1;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__timer {
    @apply lg:mb-8 justify-center;
  }

  &__reward {
    @apply mt-auto mb-0;

    &-title {
      @apply font-druk_text_cy text-white uppercase mb-4 text-left lg:text-right;
      font-size: 12px;
      line-height: 16px;
    }

    &-list {
      @apply flex items-center overflow-auto;

      &__item {
        @apply flex-shrink-0;
        width: 234px;

        &:not(:last-child) {
          @apply mr-4;
        }

        &--last {
          @apply pt-7 lg:pt-4 text-center;
          width: 104px;
          height: 112px;
          border: 1px solid #313131;
          background-color: #191818;
          border-radius: 4px;
        }

        &-count {
          @apply font-druk_text_cy font-medium leading-4 text-light-blue mr-2;
          font-size: 29px;
          text-transform: uppercase;
        }

        &-text {
          @apply text-14 leading-4 mt-3 text-white mx-auto;
          max-width: 66px;
        }
      }
    }
  }
}
.glitch {
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
}

.glitch-container {
  @apply relative;
  top: 3px;
  position: relative;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  width: 104px;
  height: 40px;
  z-index: 2;
  & svg {
    width: 100%;
    height: calc(50% + 3px);
  }
}

@screen lg {
  .landing-head {
    &__second {
      @apply flex justify-between flex-col lg:flex-row mt-4 lg:mt-3 flex-wrap;
    }
    &__left {
      &-image {
        top: -50px;
        left: -50px;
        width: auto;
      }
    }
    &__right {
    }
    &__title {
      font-size: 91px;
      line-height: 87px;
    }
    &__deadlines {
      &-title {
        font-size: 18px;
        line-height: 18px;
      }
      &-text {
        font-size: 18px;
        line-height: 26px;
      }
    }
    &__reward {
      &-title {
        font-size: 18px;
        line-height: 18px;
      }
      &-list {
        &__item {
          &--last {
            height: 96px;
          }
        }
      }
    }
  }
  .glitch-container {
    width: 214px;
    height: 73px;
  }
}
</style>

<template>
  <div class="landing">
    <modal-prizes />
    <stream
      v-if="finalStatus && finalStatus.is_stream_active"
      :url="finalStatus && finalStatus.stream_url"
      class="mt-9 w-full"
    />
    <div class="landing-head">
      <div class="landing-head__left">
        <img src="/images/hero-bg.png" alt class="landing-head__left-image" />
        <div class="landing-head__title">
          Прими участие
          <br />в
          <div
            class="landing-head__title-image glitch-container"
            @mouseover="glitch"
          >
            <icon-battle-top class="glitch top top-0" />
            <icon-battle-bottom class="glitch bottom bottom-0" />
          </div>

          <div>Data Science</div>
        </div>
      </div>
      <div class="landing-head__right">
        <div class="landing-head__deadlines">
          <div class="landing-head__deadlines-title">
            Турнир завершен
          </div>
          <DigitalTimer class="landing-head__timer" :interval="interval" />
          <div class="landing-head__deadlines-date">
            <p class="landing-head__deadlines-text">
              Отборочный этап — по 29 октября
            </p>
            <p class="landing-head__deadlines-text">Финал — 30-31 октября</p>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-head__second">
      <ButtonAuth class="mb-8 lg:mb-0" />
      <div class="landing-head__reward">
        <div class="landing-head__reward-title">Призы</div>
        <div class="landing-head__reward-list">
          <div
            v-for="(item, idx) in rewards.slice(0, 3)"
            :key="`rewards_${idx}`"
            class="landing-head__reward-list__item"
          >
            <Rewarditem
              :title="item.title"
              :place="item.place"
              :image="item.image"
            />
          </div>
          <div
            class="
              landing-head__reward-list__item
              landing-head__reward-list__item--last
            "
          >
            <div class="landing-head__reward-list__item-count">+15</div>
            <div class="landing-head__reward-list__item-text">
              призовых мест
            </div>
          </div>
        </div>
      </div>
    </div>
    <Ticker :text="tickerText" />
    <WaitingForYou />
    <Speakers />
    <Reward class="mb-10 lg:mb-24" />
    <!-- <div class="landing-title mb-2 lg:mb-10">
      Разомнись перед финалом, реши интересные задачи
    </div> -->
    <button-auth class="mb-6 lg:mb-10" />
    <swiper-issues class="mb-10" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import useGame from '@/composables/useGame'
import Stream from '@/components/Final/Stream.vue'
import DigitalTimer from '@/components/UI/DigitalTimer.vue'
import WaitingForYou from '@/components/WaitingForYou.vue'
import Reward from '@/components/Reward.vue'
import Rewarditem from '@/components/RewardItem.vue'
import About from '@/components/About.vue'
import Ticker from '@/components/Ticker.vue'
import gsap, { Power4 } from 'gsap'
import IconBattleTop from '../../components/Icons/IconBattleTop.vue'
import IconBattleBottom from '../../components/Icons/IconBattleBottom.vue'
import ModalPrizes from '../../components/ModalPrizes.vue'
import ButtonAuth from '@/components/ButtonAuth.vue'
import SwiperIssues from '../../components/SwiperIssues.vue'
import { REWARDS } from '@/consts.js'
import Speakers from '@/components/Speakers.vue'

export default {
  name: 'LandingPage',
  components: {
    DigitalTimer,
    WaitingForYou,
    Reward,
    Rewarditem,
    About,
    Ticker,
    IconBattleTop,
    IconBattleBottom,
    ModalPrizes,
    ButtonAuth,
    SwiperIssues,
    Speakers,
    Stream,
  },
  data() {
    return {
      rewards: REWARDS,
      tickerText: 'Не стой на месте, меняйся вместе с нами',
    }
  },
  setup(props, ctx) {
    const { finalStatus } = useGame()
    const endTime = new Date('2021/10/31 14:00:00')
    const interval = (endTime - Date.now()) / 1000
    return { interval, finalStatus }
  },
  props: {},
  created() {},
  mounted() {
    this.glitch()
  },
  methods: {
    glitch() {
      const tl = gsap.timeline({
        repeat: 1,
        onComplete: this.start,
      })

      tl.to('.glitch', 0.1, { color: 'rgba(255,255,255,1)' })
        .to('.glitch', 0.1, { skewX: 70, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { skewX: 0, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { opacity: 0 })
        .to('.glitch', 0.04, { opacity: 1 })
        .to('.glitch', 0.04, { x: -20 })
        .to('.glitch', 0.04, { x: 0 })
        .add('split', 0)
        .to('.top', 0.5, { x: -30, ease: Power4.easeInOut }, 'split')
        .to('.bottom', 0.5, { x: 30, ease: Power4.easeInOut }, 'split')
        .to(
          '.glitch',
          0.08,
          {
            '-webkit-filter': 'drop-shadow( 3px 3px 2px red)',
            filter: 'drop-shadow( 3px 3px 2px red)',
          },
          'split'
        )
        .to('.glitch-container', 0, { scale: 1.1 }, 'split')
        .to('.glitch-container', 0, { scale: 1 }, '+=0.02')
        .to(
          '.glitch',
          0.08,
          { '-webkit-filter': 'none', filter: 'none' },
          '+=0.09'
        )
        .to(
          '.glitch',
          0.03,
          {
            '-webkit-filter': 'drop-shadow( 3px 3px 2px green)',
            filter: 'drop-shadow( 3px 3px 2px green)',
          },
          'split'
        )
        .to(
          '.glitch',
          0.03,
          { '-webkit-filter': 'none', filter: 'none' },
          '+=0.01'
        )
        .to('.top', 0.2, { x: 0, ease: Power4.easeInOut })
        .to('.bottom', 0.2, { x: 0, ease: Power4.easeInOut })
        .to('.glitch', 0.02, { scaleY: 1.1, ease: Power4.easeInOut })
        .to('.glitch', 0.04, { scaleY: 1, ease: Power4.easeInOut })
        .to('.bottom', 0.04, { left: '-4px' })
    },
  },
  computed: {},
}
</script>
