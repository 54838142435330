<style lang="scss">
.reward {
  @apply mt-13 lg:mt-30;
  width: calc(100% + 32px);
  margin-left: -16px;

  &__container {
    @apply mx-auto flex items-center flex-col lg:flex-row;
  }

  &__col {
    @apply w-full h-full p-10 pb-0 pr-3;

    &--stats {
      @apply flex-grow;
      border-radius: 8px 8px 0 0;
      padding: 32px 16px 0 16px;
      height: 465px;
      background: linear-gradient(123.84deg, #232224 1.19%, #09080a 59.73%);
      mask-image: gradient(
        linear,
        0 300,
        0 600,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
      -webkit-mask-image: -webkit-gradient(
        linear,
        0 300,
        0 600,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
    }

    &--rewards {
      @apply pr-10;
      height: auto;
      background-color: #1c1b1b;
      border-radius: 8px;
      border: 1px solid #313131;
      padding: 24px 16px 32px 16px;
      max-width: 670px;
    }

    &-item {
      &:not(:last-child) {
        @apply mb-4;
      }
    }

    &-title {
      @apply font-druk_text_cy font-medium text-24 lg:text-32 text-left text-white uppercase mb-4;
    }

    &-text {
      @apply font-graphik_lv_regular text-14 text-white leading-5 text-left mb-6;
    }

    &-button {
      @apply font-druk_text_cy font-medium text-14 w-full py-2 uppercase mt-6 transition-colors border-0;
      background-color: #00b4e3;
      color: #141414;
      border-radius: 4px;

      &:hover {
        background-color: #2176db;
      }
      &:hover:active {
        background-color: #0f4789;
      }

      &:focus {
        outline: none;
      }

      &:active {
        @apply opacity-60;
      }
    }
  }
}

@screen lg {
  .reward {
    width: auto;
    margin-left: 0;
    &__container {
      height: 601px;
    }
    &__col {
      &--stats {
        border-radius: 12px 0 0 12px;
        padding: 42px 42px 0 42px;
        height: 100%;
      }
      &--rewards {
        max-width: 345px;
        border-radius: 0 12px 12px 0;
        border: none;
        padding: 42px 42px 35px 42px;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="reward">
    <div class="reward__container">
      <div class="reward__col reward__col--stats">
        <main-stats-widget />
      </div>
      <div class="reward__col reward__col--rewards">
        <div class="reward__col-title">Выиграй призы</div>

        <div class="reward__col-list">
          <div
            v-for="(item, idx) in rewards"
            :key="`rewards_${idx}`"
            class="reward__col-item"
          >
            <RewardItem
              :title="item.title"
              :place="`${idx + 1} место`"
              :image="item.image"
            />
          </div>
        </div>
        <button
          class="button reward__col-button"
          @click="$modal.show('modal-prizes')"
        >
          Все призы
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RewardItem from '@/components/RewardItem.vue'
import MainStatsWidget from '@/components/MainStatsWidget.vue'
import { REWARDS } from '@/consts.js'

export default {
  name: 'Reward',
  components: {
    MainStatsWidget,
    RewardItem,
  },
  data() {
    return {
      rewards: REWARDS.slice(0, 3),
    }
  },
}
</script>
