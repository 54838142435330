<style lang="scss">
.timer {
  @apply flex;
}
.timer-block {
  @apply inline-block;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
}
.timer-item {
  @apply rounded-full flex items-center justify-center font-druk_text_cy relative rounded-full border-2;
  --size: 50px;
  width: var(--size);
  height: var(--size);
  background: rgba(white, 0.05);
  border-color: rgba(white, 0.25);
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  &--with-divider {
    &:after {
      content: '';
      @apply absolute bg-no-repeat;
      width: 4px;
      height: 12px;
      top: 18px;
      left: calc(100% + 14px);
      background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.807739 4.3978H4.68323V0.134766H0.807739V4.3978ZM0.807739 12.6471H4.68323V8.38402H0.807739V12.6471Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  &__name {
    @apply font-graphik_lv_regular text-center mt-2 lg:mt-4;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
  }
}

@screen lg {
  .timer-block {
    margin-right: 43px;
  }
  .timer-item {
    --size: 94px;
    font-size: 52px;
    line-height: 52px;
    &--with-divider {
      &:after {
        width: 7px;
        height: 23px;
        top: 36px;
        left: calc(100% + 18px);
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='24' viewBox='0 0 8 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.25605H7.28V0.248047H0V8.25605ZM0 23.752H7.28V15.744H0V23.752Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
</style>

<template>
  <div class="timer">
    <div class="timer-block">
      <span class="timer-item">{{ days }}</span>
      <div class="timer-item__name">Дней</div>
    </div>
    <div class="timer-block">
      <span class="timer-item timer-item--with-divider">{{ hours }}</span>
      <div class="timer-item__name">Часов</div>
    </div>
    <div class="timer-block">
      <span class="timer-item timer-item--with-divider">{{ minutes }}</span>
      <div class="timer-item__name">Минут</div>
    </div>
    <div class="timer-block">
      <span class="timer-item">{{ seconds }}</span>
      <div class="timer-item__name">Секунд</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DigitalTimer',
  props: {
    // value in seconds
    interval: {
      type: [String, Number],
      required: true,
    },
    handleEndTime: {
      type: Function,
      default: (ctx) => {
        ctx.stopTimer()
      },
    },
  },
  data() {
    return {
      currentTime: this.interval > 0 ? Math.round(this.interval) : 0,
      timer: null,
    }
  },
  computed: {
    days() {
      return Math.floor(this.currentTime / 86400)
    },
    hours() {
      return Math.floor(((this.currentTime % 31536000) % 86400) / 3600)
        .toString()
        .padStart(2, '0')
    },
    minutes() {
      return Math.floor((((this.currentTime % 31536000) % 86400) % 3600) / 60)
        .toString()
        .padStart(2, '0')
    },
    seconds() {
      return ((((this.currentTime % 31536000) % 86400) % 3600) % 60)
        .toString()
        .padStart(2, '0')
      return `${numdays}:${numhours}:${numminutes}:${numseconds}`
    },
  },
  watch: {
    interval(val) {
      this.currentTime = val
    },
  },
  mounted() {
    if (this.interval > 0) {
      this.startTimer()
    }
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1

        if (this.currentTime <= 0) {
          this.handleEndTime(this)
          this.stopTimer()
        }
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
}
</script>
