<style lang="scss">
.about {
  @apply text-left mt-21 lg:mt-36;

  &__title {
    @apply font-druk_text_cy text-24 lg:text-32 font-medium text-white leading-8 lg:leading-10
      text-left uppercase mb-4 lg:mb-5 opacity-80;
    letter-spacing: 0.03em;
  }

  &__description {
    @apply mb-12 lg:mb-16;

    &-text {
      @apply text-18 text-white leading-6;

      &:not(:last-child) {
        @apply mb-4 lg:mb-5;
      }

      &__bold {
        @apply font-bold;
      }
    }
  }

  &__list {
    @apply flex items-start flex-col lg:flex-row text-white justify-center;
    margin: 0 -30px;

    &-item {
      @apply relative lg:w-full;
      margin-left: 30px;
      margin-right: 30px;
      padding-right: 40px;

      &:not(:last-child) {
        @apply pb-8 lg:pb-0 mb-7 lg:mb-0;
        &::after {
          content: '';
          @apply absolute;
          right: auto;
          left: 0;
          top: auto;
          bottom: 0;
          width: 78px;
          height: 1px;
          background-color: #ffffff;
        }
      }
    }

    &-title {
      @apply font-druk_text_cy mb-2 lg:mb-4 uppercase;
      font-size: 18px;
      line-height: 20px;
    }

    &-text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
@screen lg {
  .about {
    &__description {
      &-text {
        font-size: 38px;
        line-height: 120%;
      }
    }
    &__list {
      &-item {
        width: calc(100% / 3 - 60px);
        &:not(:last-child) {
          &::after {
            right: 0;
            left: auto;
            top: 70px;
            bottom: auto;
            width: 1px;
            height: 78px;
            transform: translateY(-50%);
          }
        }
      }
      &-title {
        font-size: 24px;
        line-height: 24px;
      }

      &-text {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
</style>

<template>
  <div class="about">
    <div class="about__container">
      <div class="about__title">О турнире</div>
      <div class="about__description">
        <div class="about__description-text about__description-text--opacity">
          <span style="color: #00b4e3">«Открытие»</span> стремится вперёд:
          <br />
          мы в ответе за изменения к лучшему.
        </div>
        <div class="about__description-text">
          Не&nbsp;стой на&nbsp;месте&nbsp;&mdash; меняйся вместе с&nbsp;нами.
        </div>
        <div class="about__description-text">
          Пройди отборочные, попади в&nbsp;финал турнира по&nbsp;<span
            style="color: #00b4e3"
            >Data Science</span
          >
          и&nbsp;выиграй крутые призы.<br />
        </div>
      </div>
      <div class="about__list">
        <div
          v-for="{ title, descr } in items"
          :key="title"
          class="about__list-item"
        >
          <div class="about__list-title">{{ title }}</div>
          <div class="about__list-text">{{ descr }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      items: [
        {
          title: 'Как принять участие',
          descr:
            'Просто зарегистрируйся: после регистрации тебе откроются задания.',
        },
        {
          title: 'Как пройти в финал',
          descr:
            'Реши как минимум две задачи из отборочного тура и обеспечь себе место в финале.',
        },
      ],
    }
  },
}
</script>
